<div class="loading" *ngIf="loading"></div>

<section class="end error centered" *ngIf="!loading">
    <div class="wrap">
  <h2>Qualcosa è andato storto</h2>
      <div *ngIf="genericError">
        <p>Non è stato possibile verificare la carta di credito sui sistemi di Gestpay<br>Riprovare tra qualche minuto o contattare l'assistenza</p>
      </div>
      <div *ngIf="!genericError">
        <p>{{errorMessage}}</p>
      </div>
        <br>
        <button class="btn tertiary" routerLink="/payment-method" routerLinkActive="active">Indietro</button>
    </div>
  </section>

<app-dialog-change-installments [data]="forcedInstallments" (close)="closeDialogChangeInstallments($event)" #dialogChangeInstallments></app-dialog-change-installments>

