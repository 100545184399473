<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <div class="loading" *ngIf="loading"></div>
        <div *ngIf="!loading">
            <button class="btn-back" (click)="closeTooltip()">Annulla <b>&#10006;</b></button>
            <div class="div_alert" style="color: black;">
                <div>
                    <h3><b>Attenzione!</b></h3>
                    <p>Se paghi con carta <b>durante l'autenticazione visualizzerai l'intero importo del prodotto/servizio</b> 
                    e non la singola quota. Ci teniamo a rassicurarti che in questa fase non avverrà alcun addebito: solo al
                    termine della procedura verrà addebitata la quota di {{installmentAmount}} €.<br><br>
                    Inoltre invieremo una richiesta di pre-autorizzazione di 0,01 € per verificare la tua carta; non ti 
                    preoccupare, decade automaticamente non appena autorizziamo il pagamento.
                    </p>
                    <div class="cmd">
                        <button type="button" [disabled]="disabled" class="btn tertiary small" id="dialog-close" (click)="confirm()">Procedi</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
