import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { OnBoardingService } from 'src/app/services/onboarding.service';
import { RouterService } from 'src/app/services/router.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

declare var GestPay;
declare var BrowserEnabled;

@Component({
  selector: 'app-dialog-confirm-credit-card-payment-method',
  templateUrl: './dialog-confirm-credit-card-payment-method.component.html',
  styleUrls: ['./dialog-confirm-credit-card-payment-method.component.css']
})
export class DialogConfirmCreditCardPaymentMethodComponent implements OnInit {

  @Output() close : EventEmitter<any> = new EventEmitter<any>();

  constructor(
      public route : RouterService
      , public user : UserService
    ) { }

  show : boolean = false;
  loading : boolean = false;
  @Input('installmentAmount') installmentAmount: string;
  disabled : boolean = false;

  ngOnInit() { }

  openTooltip() {
    this.show = true;
    this.disabled = false;
    document.body.classList.add('locked');
  }

  closeTooltip(err = false) {
    this.disabled = true;
    document.body.classList.remove('locked');
    this.close.emit(false);
    this.show = false;
  }

  confirm() {
    this.disabled = true;
    this.close.emit(true);
  }


}
